export function Home() {
  return (
    <div>
        <h2>Home</h2>
        Welcome to my app
        <br/><br/>
        Click around!
        <br/><br/>
        Here is a picture of me:
        <br/>
        <br/>
        <img src={require("./pb.jpg")} alt={"Me"} width="142"/>
    </div>
  )
}
