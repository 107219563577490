import {useState} from 'react'
import { useInterval } from './useInterval';

export function About() {
    const [inHover, setHover] = useState(false);
    function Name(Name){
        function getRandomNum(){
            return Math.random() < 0.5 ? 1 : 0
        }
        function getRandomNumbers(Name){
            let randomNumbers = []
            for(let i = 0;i < Name.length;i++){
                randomNumbers += getRandomNum()
            }
            return randomNumbers
        }
        
        const [hiddenName, setHiddenName] = useState(getRandomNumbers(Name))

        useInterval(()=>{
            setHiddenName(getRandomNumbers(Name))
        }, 1000)

        return (
            <div 
            // Thank you: https://dev.to/danielleye/react-create-on-hover-event-with-react-hooks-2j78
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)} style={{display:"inline-block"}}>
                {inHover ? Name : hiddenName}
                
            </div>
        )
    }
    return (
    <div>
        <h2>About</h2>
            My name is {Name('Rasmus')}.
            <br/><br/>
            I code React for fun and do PowerShell for work.
            <br/><br/>
            Collab on a PowerShell-script, get scripts made by made or anything else please write to: r@dataz.direct
            <br/><br/>
    </div>
    )
}
