import React, { useState, useEffect } from 'react';
import {WbSunny, Brightness2} from '@material-ui/icons'

// Get the OS' prefers-color-scheme, for use in the default setTheme in useState
const darkmodePref = window.matchMedia("(prefers-color-scheme: dark )")

export default function ThemeChange() {
    function getDefaultColor(){
        return localStorage.getItem('theme') ? localStorage.getItem('theme') : 
        darkmodePref.matches ? 'dark' : 'light'
    }
    // Creating the setTheme
    const [theme, setTheme] = useState(getDefaultColor())

    // This will happen everytime 'theme' changes
    useEffect( () => {
        const themeModes = {
            dark: {
                backgoundColor: 'black',
                fontColor: 'white'
            },
            light: {
                backgoundColor: 'white',
                fontColor: 'black'
            }
        }
        
        // Make the actual changes
        // Thank you: https://stackoverflow.com/a/57693953
        document.body.style.background = themeModes[theme].backgoundColor
        document.documentElement.style.setProperty('--backgoundColor', themeModes[theme].fontColor);
        document.documentElement.style.setProperty('--fontColor', themeModes[theme].fontColor);
        function handler(){
            darkmodePref.matches ? setTheme('dark') : setTheme('light')
        }
        // This will write it to the local storage
        localStorage.setItem('theme', theme)
        darkmodePref.addEventListener( 'change', handler)
        return () => darkmodePref.removeEventListener('change', handler);
        

        // This is very important, everytime changes happen to: the theme, useEffect will happen once again.
    }, [theme])

    // Add OSChange-handler
    // Thank you: https://stackoverflow.com/a/59621903
    return (
        <div style={{display:"inline-block"}}>
            { 
                theme === 'dark' 
                    ? <Brightness2 onClick={()=>{setTheme(theme === 'dark' ? 'light' : 'dark')}}/> 
                    : <WbSunny onClick={()=>{setTheme(theme === 'dark' ? 'light' : 'dark')}}/>
            }
        </div>
    )
}
