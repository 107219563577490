import './main.css'
import ThemeChange from './ThemeChange'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { About } from './About';
import { Home } from './Home';
import { PowerShell } from './PowerShell';

function App() {
  return (
    <Router>
      <div>
        <div>
        <nav>
          <ThemeChange/>
          <Link to="/">Home</Link>
          <Link to="/about">About me</Link>
          <Link to="/powershell">PowerShell</Link>
        </nav>
        </div>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/powershell">
            <PowerShell />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="*">
            <Nomatch/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Nomatch(){
  let location = useLocation()
  return (
      <h2>
        Sorry.. we don't know: <code>{location.pathname}</code> 
      </h2>
  )
}

export default App;
