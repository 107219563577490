import { useState } from 'react'
import styled from 'styled-components'

export function PowerShell() {
  const Button = styled.span`
    display: block;
    width: 200px;
    height: 40px;
    position: relative;
    border: 2px solid var(--fontColor);
    cursor: pointer;
    text-transform: uppercase;
  `
  const ButtonText = styled.div`
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 16px;
    transform: translate(-50%, -50%);
  `
  const CopyClick = ({text}) => {
    const [buttonText, setButtonText, ] = useState('COPY SCRIPT')
    return <Button onClick = {
      () => {
        navigator.clipboard.writeText(text)
        setButtonText('COPIED')
        setTimeout(() => {  setButtonText('COPY SCRIPT'); }, 1000);
      }
    }>
      <ButtonText>
        {buttonText}
      </ButtonText>
    </Button>
  }
  return (
    <div>
      <br />
        <CopyClick text='powershell iwr -useb https://git.io/Jy1s3|iex'/>
      <br />
      <span style={{color: '#006400'}}># 15:19 29/12/2021</span><br />
      <span style={{color: '#006400'}}># Rasmus R&#248;ssum</span><br />
      <span style={{color: '#006400'}}># x@dataz.direct</span><br />
      <span style={{color: '#006400'}}># @maybemalware</span><br />
      <br />
      <span style={{color: '#006400'}}># Explorer sometimes has to be restarted - MS has made a restart function in Task Manager - but it DOES NOT open the Explorer-windows it just closed - this script does!</span><br />
      <br />
      <span style={{color: '#006400'}}># Get LocationURL for the currently opened Explorer-windows</span><br />
      <span style={{color: '#FF4500'}}>$workingPaths</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#A9A9A9'}}>=</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: 'null'}}>(</span><span style={{color: '#0000FF'}}>New-Object</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#000080'}}>-ComObject</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#8A2BE2'}}>Shell.Application</span><span style={{color: 'null'}}>)</span><span style={{color: '#A9A9A9'}}>.</span><span style={{color: 'null'}}>Windows</span><span style={{color: 'null'}}>(</span><span style={{color: 'null'}}>)</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#A9A9A9'}}>|</span><span style={{color: '#0000FF'}}>select</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#000080'}}>-exp</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#8A2BE2'}}>LocationURL</span><br />
      <span style={{color: '#006400'}}># Stop Explorer for the refresh</span><br />
      <span style={{color: '#00008B'}}>try</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: 'null'}}>{"{"}</span><br />
      <span style={{color: 'null'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{color: '#006400'}}># Try stop explorer</span><br />
      <span style={{color: 'null'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{color: '#0000FF'}}>Stop-Process</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#000080'}}>-Name</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#8A2BE2'}}>explorer</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#000080'}}>-ErrorAction</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#8A2BE2'}}>Stop</span><br />
      <span style={{color: 'null'}}>{"}"}</span><span style={{color: '#00008B'}}> catch</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: 'null'}}>{"{"}</span><br />
      <span style={{color: 'null'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{color: '#006400'}}># Break script</span><br />
      <span style={{color: 'null'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{color: '#8B0000'}}>&#39;Could not stop Explorer&#39;</span><br />
      <span style={{color: 'null'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{color: '#00008B'}}>break</span><br />
      <span style={{color: 'null'}}>{"}"}</span><br />
      <br />
      <span style={{color: '#006400'}}># Start Explorer for each closed path</span><br />
      <span style={{color: '#FF4500'}}>$workingPaths</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#A9A9A9'}}>|</span><span style={{color: '#0000FF'}}>foreach</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: 'null'}}>{"{"}</span><br />
      <span style={{color: 'null'}}>&nbsp;&nbsp;&nbsp;&nbsp;</span><span style={{color: '#0000FF'}}>explorer</span><span style={{color: 'null'}}>&nbsp;</span><span style={{color: '#FF4500'}}>$_</span><br />
      <span style={{color: 'null'}}>{"}"}</span>
    </div>
  )
}